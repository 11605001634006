<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col
      cols="12"
      sm="8"
      md="6"
    >
      <v-form @submit="login">
        <v-card class="elevation-12">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>Prihlásenie</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-text-field
              v-model="username"
              label="Prihlasovacie meno"
              name="login"
              prepend-icon="mdi-account"
              type="text"
            />
            <v-text-field
              id="password"
              v-model="password"
              label="Heslo"
              name="password"
              prepend-icon="mdi-lock"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            />
            <v-card-actions>
              <v-row justify="space-between">
                <v-col cols="auto">

                  <v-btn
                    color="primary"
                    outlined
                    to="password_reset"
                  >
                    Zabudnuté heslo
                  </v-btn>
                </v-col>
                <v-col cols="auto">

                  <v-btn
                    color="primary"
                    @click="login"
                  >
                    Prihlásiť sa
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    source: String,
  },
  data() {
    return {
      drawer: null,
      username: "",
      password: "",
      show1: false,
    };
  },

  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },

  methods: {
    login() {
      let username = this.username;
      let password = this.password;

      this.$store
        .dispatch("auth/login", { username, password })
        .then(() => {
          this.$router.push("/");
        }).catch(() => {

        });
    },
  },
};
</script>